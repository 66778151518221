import {
  Modal as CModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ModalProps,
} from "@chakra-ui/react";

interface IModal extends ModalProps {
  header?: any;
  footer?: any;
  children: any;
  showCloseButton?: boolean;
}

const Modal = (props: IModal) => {
  const { header, footer, children, showCloseButton } = props;

  return (
    <CModal isCentered {...props}>
      <ModalOverlay />
      <ModalContent m={4}>
        {header ? <ModalHeader>{header}</ModalHeader> : ""}

        {showCloseButton === false ? <> </> : <ModalCloseButton />}

        <ModalBody>{children}</ModalBody>

        {footer ? <ModalFooter> {footer}</ModalFooter> : ""}
      </ModalContent>
    </CModal>
  );
};

export default Modal;
