import React, { useState, createContext, FC, useEffect } from "react";
import { find, filter, orderBy, map } from "lodash";
import getWeeks from "./helpers/date";
import { calculateGap } from "./helpers/calculate";
import axios from "axios";

const StateContext = createContext<any>({});

const generateMileStoneArrays = (val: number, startVal = 0) => {
  return [
    startVal,
    Math.round((val - startVal) * 0.25 + startVal),
    Math.round((val - startVal) * 0.5 + startVal),
    Math.round((val - startVal) * 0.75 + startVal),
    val,
  ];
};

export const weeks = {
  mdrt: getWeeks("December 31, 2021 23:13:00"),
  ceo: getWeeks("June 30, 2022 23:13:00"),
};

export const BASE = {
  FYC: 50000,
  FYP: 100000,
};

const StateContextWrapper: FC<{
  scode?: string;
}> = ({ scode, children }) => {
  const [campaign, setCampaign] = useState("mdrt"); // MDRT, CEO
  const [target, setTarget] = useState({
    mdrt: {
      fyp: 2938300,
      fyc: 734700,
    },
    ceo: {
      fyp: 8816400,
      fyc: 2204100,
    },
  });

  const [milestones, setMilestones] = useState({
    mdrt: {
      fyp: generateMileStoneArrays(target.mdrt.fyp),
      fyc: generateMileStoneArrays(target.mdrt.fyc),
    },
    ceo: {
      fyp: generateMileStoneArrays(target.ceo.fyp),
      fyc: generateMileStoneArrays(target.ceo.fyc),
    },
  });

  const [qualified, setQualifed] = useState({
    mdrt: 0,
    ceo: 0,
  });

  const [data] = useState({
    mdrt: [
      {
        type: "MDRT",
        fyp: 2938800,
        premiumMileStones: generateMileStoneArrays(2938800),
        fyc: 734700,
        comissionMileStones: generateMileStoneArrays(734700),
      },
      {
        type: "MDRT COT",
        fyp: 8816400,
        premiumMileStones: generateMileStoneArrays(8816400, 2938800),
        fyc: 2204100,
        comissionMileStones: generateMileStoneArrays(2204100, 734700),
      },
      {
        type: "MDRT TOT",
        fyp: 17632800,
        premiumMileStones: generateMileStoneArrays(17632800, 8816400),
        fyc: 4408200,
        comissionMileStones: generateMileStoneArrays(4408200, 2204100),
      },
    ],
    ceo: [
      {
        type: "CEO SELECT",
        fyp: 2900000,
        premiumMileStones: generateMileStoneArrays(2900000),
        fyc: 740000,
        comissionMileStones: generateMileStoneArrays(740000),
      },
      {
        type: "CEO CABINET",
        fyp: 4000000,
        premiumMileStones: generateMileStoneArrays(4000000, 2900000),
        fyc: 1100000,
        comissionMileStones: generateMileStoneArrays(1100000, 740000),
      },
    ],
  });

  const [sapcode, setSapcode] = useState(scode ? parseInt(scode) : 0);
  const [user, setUser] = useState<any>(null);
  const [usersArray, setUsersArray] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setSapcode(scode ? parseInt(scode) : 0);
  }, [scode]);

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const response = (
        await axios.get(`/data/data.json?${Math.random() * 99999}`)
      ).data;

      if (response && response.Sheet1) {
        setUsersArray(formatUserData(response.Sheet1 || [], setQualifed));
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (usersArray.length) {
      let u = find(usersArray, (uu) => uu?.SAP == sapcode);

      if (u) {
        // GAP REQUIRED
        u["MDRT"]["FYCGAP"] = calculateGap(u, target.mdrt.fyc, "MDRT", "FYC");
        u["MDRT"]["FYPGAP"] = calculateGap(u, target.mdrt.fyp, "MDRT", "FYP");
        u["CEO"]["FYCGAP"] = calculateGap(u, target.ceo.fyc, "CEO", "FYC");
        u["CEO"]["FYPGAP"] = calculateGap(u, target.ceo.fyp, "CEO", "FYP");

        // CALCULATE QRS REQUIRED TOTAL
        u["MDRT"]["FYCWEEKS"] = calculateQRrequired(
          u,
          target.mdrt.fyc,
          "MDRT",
          "FYC"
        );

        u["MDRT"]["FYPWEEKS"] = calculateQRrequired(
          u,
          target.mdrt.fyc,
          "MDRT",
          "FYP"
        );

        u["CEO"]["FYCWEEKS"] = calculateQRrequired(
          u,
          target.ceo.fyc,
          "CEO",
          "FYC"
        );

        u["CEO"]["FYPWEEKS"] = calculateQRrequired(
          u,
          target.ceo.fyc,
          "CEO",
          "FYP"
        );
        setUser(u);
      }
    }
  }, [usersArray, sapcode]);

  const getUsersArray = () => usersArray;

  return (
    <StateContext.Provider
      value={{
        campaign,
        setCampaign,
        target,
        milestones,
        isLoading,
        app: {
          qualified,
          user,
          getUsersArray,
          weeks,
          data,
        },
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export { StateContextWrapper };

export default StateContext;

const formatUserData = (unFormattedData: Array<any>, setQ?: any) => {
  let formattedData = [...unFormattedData];

  let qMDRTUsers = filter(formattedData, (u) => u?.MDRT?.STATUS == "QUALIFIED");
  let qCEOUsers = filter(formattedData, (u) => u?.CEO?.STATUS == "QUALIFIED");

  if (setQ) {
    setQ({
      mdrt: qMDRTUsers.length,
      ceo: qCEOUsers.length,
    });
  }

  // MDRT FYP POS
  formattedData = SortAndAddPos(formattedData, "MDRT", "MDRT.FYP", "FYPPOS");
  // MDRT FYC POS
  formattedData = SortAndAddPos(formattedData, "MDRT", "MDRT.FYC", "FYCPOS");
  // CEO FYP POS
  formattedData = SortAndAddPos(formattedData, "CEO", "CEO.FYP", "FYPPOS");
  // CEO FYC POS
  formattedData = SortAndAddPos(formattedData, "CEO", "CEO.FYC", "FYCPOS");

  return formattedData;
};

export const SortAndAddPos = (
  arr: Array<any>,
  typ: string,
  prty: string,
  postitle: string
) => {
  let newArr = orderBy(arr, prty, "desc");

  return map(newArr, (data, index) => {
    return {
      ...data,
      [typ]: {
        ...data[typ],
        [postitle]: index + 1,
      },
    };
  });
};

export const calculateQRrequired = (
  u: any,
  total: number,
  typ: string,
  prty: string
) => {
  let QRreq = 0;

  const gapText = `${prty}GAP`;
  const gapAmt = u[typ][gapText];

  const weeks = getWeeks(typ);

  const targetPerWeek = gapAmt / weeks;

  let amtPerQr = 0;

  if (prty == "FYC") {
    amtPerQr = BASE.FYC / 8;
  } else if (prty == "FYP") {
    amtPerQr = BASE.FYP / 8;
  }

  return Math.ceil(targetPerWeek / amtPerQr);
};
