import { Flex, Box, Text } from "@chakra-ui/react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useHistory } from "react-router-dom";

interface IPageHeader {
  title?: string;
}

const PageHeader = ({ title }: IPageHeader) => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <>
      <Flex direction="row" width="100%">
        <Flex mr={4} onClick={() => goBack()} align="center">
          <Box color="gray.400" as={MdOutlineArrowBackIosNew} mr={1} />
          <Text color="gray.400">Back</Text>
        </Flex>

        <Text mx="auto" fontFamily="heading" fontSize="lg" color="primary.500">
          {title}
        </Text>

        <Box
          opacity="0"
          color="gray.400"
          as={MdOutlineArrowBackIosNew}
          mr={1}
        />
        <Text mr={4} opacity="0" color="gray.400">
          Back
        </Text>
      </Flex>
    </>
  );
};

export default PageHeader;
