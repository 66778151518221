import React, { useRef, useEffect, useState } from "react";
import Layout from "../components/layout";
import { Flex, Box, Text, useDimensions } from "@chakra-ui/react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import PageHeader from "../components/page-header";
import ReactPlayer from "react-player";
import map from "lodash/map";
import Disclaimer from "../components/disclaimer";

const SuccessStories = () => {
  const [isPlaying, setIsPlaying] = useState("");

  const youtubeVideoURLs = [
    "https://youtu.be/XNleDjufoTk",
    "https://youtu.be/cJJ4QxuLVJA",
    "https://youtu.be/fufk6Shb_PY",
    "https://youtu.be/0QpTI1hGx5s",
    "https://youtu.be/9LKhpjgYhLc",
    "https://youtu.be/0IMq0HzbxaE",
    "https://youtu.be/863rz3zAfIU",
  ];

  return (
    <Layout p={4}>
      <PageHeader title="Success Stories" />

      <br />

      {map(youtubeVideoURLs, (ytVideo) => (
        <YoutubeVideoPlayer
          url={ytVideo}
          key={ytVideo}
          isPlaying={isPlaying}
          SetIsPlaying={setIsPlaying}
        />
      ))}

      <Box p={2}>
        <Disclaimer />
      </Box>
    </Layout>
  );
};

export default SuccessStories;

interface IYoutubeVideoPlayer {
  url: string;
  isPlaying: string;
  SetIsPlaying: (url: string) => void;
}

const YoutubeVideoPlayer = ({
  url,
  isPlaying,
  SetIsPlaying,
}: IYoutubeVideoPlayer) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(200);
  const [width, setWidth] = useState(200);

  useEffect(() => {
    if (wrapperRef.current && wrapperRef.current.clientWidth) {
      let w = wrapperRef.current.clientWidth;
      let h = wrapperRef.current.clientWidth / 1.77;
      setHeight(h);
      setWidth(w);
    }
  }, [wrapperRef]);

  const playPauseVideo = () => {
    if (isPlaying == url) {
      SetIsPlaying("");
    } else {
      SetIsPlaying(url);
    }
  };

  return (
    <Box ref={wrapperRef} mb={6} onClick={playPauseVideo}>
      <ReactPlayer
        width={width}
        height={height}
        url={url}
        playing={isPlaying == url ? true : false}
        onStart={() => {
          playPauseVideo();
        }}
        onPlay={() => {
          playPauseVideo();
        }}
      />
    </Box>
  );
};
