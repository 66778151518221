import Layout from "../components/layout";
import { Box, Spinner, Text, Image, Divider } from "@chakra-ui/react";
import RaceTrack from "../assets/RaceTrack.jpg";
import BlueCar from "../assets/car/blue.png";
import Chart from "react-apexcharts";
import QRgauge from "../components/qr/gauage";
import RunRateChart from "../components/home/run-rate-chart";
import Disclaimer from "../components/disclaimer";

const RoadMapScreen = () => {
  return (
    <Layout>
      <Image src="/images/roadMap3.jpeg" />

      <Box my={4} textAlign="center" display="none">
        <Text fontWeight="bold" fontSize="lg">
          Run Rate Per / Week
        </Text>

        <Text px={4}>
          In order to achieve your goal, you will need to score policies with
          the following run rate with premium or commission
        </Text>

        <RunRateChart />
      </Box>

      <Box px={4} mt={4} display="none">
        <Divider color="gray.400" width="100%" />
      </Box>

      <QRgauge />

      <Box p={2}>
        <Disclaimer />
      </Box>

      <br />
      <br />
      <br />
      <br />
    </Layout>
  );
};

export default RoadMapScreen;

const Car = () => {
  return <Image src={BlueCar} height="25px" transform="rotate(-155deg)" />;
};

const RunRateRequired = () => {
  return (
    <Box p={4}>
      <Text mb={2} color="gray.400">
        Run rate required per week
      </Text>

      <BarChartComponent />
    </Box>
  );
};

export const BarChartComponent = () => {
  const options = {
    xaxis: {
      // categories: ["W1", "W2", "W3", "W4", "W5"],
    },

    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },
  };

  const series = [
    {
      name: "TARGET",
      data: [29, 29, 29, 29, 29],
    },
    {
      name: "LOGIN",
      data: [20, 20, 20, 20, 20],
    },
    {
      name: "ISSUANCE",
      data: [12, 12, 12, 12, 12],
    },
    {
      name: "ISSUABLE",
      data: [10, 10, 10, 10, 10],
    },
    {
      name: "GAP",
      data: [8, 8, 8, 8, 8],
    },
  ];

  return (
    <Box mx="auto" mb={6}>
      <Chart options={options} series={series} type="line" width="360" />
    </Box>
  );
};
