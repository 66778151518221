import React, { useState } from "react";
import ListItem from "./list-item";
import { BsFillShieldFill } from "react-icons/bs";
import {
  useDisclosure,
  Text,
  InputGroup,
  InputLeftAddon,
  Input,
  Flex,
  Button,
} from "@chakra-ui/react";
import Modal from "../elements/modal";

const ProtectoMeter = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [mobileNo, setMobileNo] = useState("");

  const createMessage = () => {
    return ` https://lifelineuat.reliancelife.com/Term_Calc/home
    
    Hi, try out this calculator to calculate your required life insurance amount & premium.
`;
  };

  const handleShare = () => {
    const url = `https://wa.me/91${mobileNo}?text=${encodeURI(
      createMessage()
    )}`;

    window.open(url, url);

    setMobileNo("");
    onClose();
  };

  const isMobileNumberValid = () => {
    if (mobileNo.length != 10) {
      return false;
    }

    if (isNaN(parseInt(mobileNo))) {
      return false;
    }

    return true;
  };

  return (
    <>
      <ListItem
        icon={BsFillShieldFill}
        colorScheme="primary"
        title="Protect-o-meter"
        onPress={() => onOpen()}
      />
      <Modal isOpen={isOpen} onClose={onClose} header="Share Protect-o-meter">
        <Text fontSize="sm" color="gray.400" mb={4}>
          Please enter the mobile number with which you would like to share the
          calculator
        </Text>

        <InputGroup colorScheme="gray" mb={6}>
          <InputLeftAddon borderColor="gray.200" children="+91" />
          <Input
            value={mobileNo}
            onChange={(e) => setMobileNo(e.target.value)}
            type="tel"
            placeholder="phone number"
          />
        </InputGroup>

        <Flex
          mb={4}
          mt={4}
          direction="row"
          align="center"
          justify="space-between"
        >
          <Button fontWeight="normal" variant="ghost">
            Cancel
          </Button>

          <Button
            disabled={!isMobileNumberValid()}
            fontWeight="normal"
            colorScheme="primary"
            onClick={handleShare}
          >
            Share On Whatsapp
          </Button>
        </Flex>
      </Modal>
    </>
  );
};
export default ProtectoMeter;
