const getWeeks = (type?: string, d?: string) => {
  let dt1 = new Date();
  let dt2: any;

  if (d) {
    dt2 = new Date(d);
  }

  if (type) {
    dt2 = new Date(
      type === "MDRT" ? "December 31, 2021 23:13:00" : "June 30, 2022 23:13:00"
    );
  }

  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60 * 60 * 24 * 7;
  return Math.abs(Math.round(diff));

  return 0;
};

export default getWeeks;
