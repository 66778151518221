import React, { FC } from "react";

import { Flex, FlexProps } from "@chakra-ui/react";

import Styles from "./layout.module.scss";

const Layout: FC<FlexProps> = (props) => {
  return (
    <Flex
      className={Styles.layout}
      direction="column"
      {...props}
      width="100vw"
      maxWidth="600px"
      mx="auto"
      overflowX="hidden"
    >
      {props.children}
    </Flex>
  );
};

export default Layout;
