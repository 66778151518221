import React, { FC, useContext } from "react";
import {
  Box,
  Flex,
  Text,
  FlexProps,
  Avatar,
  Badge,
  useWhyDidYouUpdate,
} from "@chakra-ui/react";
import users from "../../data/users";
import { AnimatePresence, motion } from "framer-motion";
import IUserModel from "../../models/user.model";
import storageService from "../../service/storage";
import { STORAGE } from "../../constants";
import { RiWhatsappFill } from "react-icons/ri";
import StateContext from "../../context";
import FormatNumber from "../../helpers/format-number";

interface ILeaderBoard {
  type: string;
  users: Array<any>;
}

const LeaderBoard = ({ type, users }: ILeaderBoard) => {
  console.log(users);

  const stateCtx = useContext(StateContext);

  const user = stateCtx?.app?.user;

  if (!user) {
    return <> </>;
  }

  return (
    <Box p={4}>
      <Flex
        direction="column"
        width="100%"
        bg="white"
        overflow="hidden"
        boxShadow="-8px 12px 18px 0 rgb(25 42 70 / 13%)"
        p={0}
      >
        {users.map((u) => {
          return (
            <LeaderBoardRow
              key={u.SAP}
              isYou={u.SAP === user.SAP ? true : false}
              u={u}
              campaign={stateCtx?.campaign}
              type={type}
            />
          );
        })}
      </Flex>
    </Box>
  );
};

export default LeaderBoard;

interface ILeaderBoardRow {
  u: any;
  isYou?: boolean;
  type: string;
  campaign: string;
}

const LeaderBoardRow: FC<ILeaderBoardRow> = ({
  u,
  isYou,
  type,
  campaign,
}: ILeaderBoardRow) => {
  const sendMessageOnWhatsapp = () => {};

  return (
    <Flex
      direction="row"
      px={4}
      py={2}
      align="center"
      bg={isYou ? "secondary.500" : "white"}
    >
      <Text mr={1} minWidth="40px">
        {u[campaign?.toUpperCase()][`${type?.toUpperCase()}POS`]}
      </Text>
      <Avatar
        name={u?.Name}
        width="40px"
        height="40px"
        mr={6}
        src={`/images/superhero/${
          u[campaign?.toUpperCase()][`${type?.toUpperCase()}POS`]
        }.png`}
      />

      <Box width="100%">
        <Text mr={3} color={isYou ? "white" : "black"}>
          {u?.Name}
        </Text>
        <Text
          fontSize="sm"
          color={isYou ? "gray.white" : "gray.400"}
          mr={3}
          textTransform="uppercase"
        >
          &#8377; &nbsp;
          {FormatNumber(u[campaign?.toUpperCase()][`${type?.toUpperCase()}`])}
          &nbsp;
          {/* {type?.toUpperCase() === "CEO" ? "CEO's CLUB" : "MDRT"} */}
        </Text>
      </Box>

      {/* {isYou ? (
        <> </>
      ) : (
        <Box
          fontSize="36px"
          as={RiWhatsappFill}
          ml="auto"
          color="whatsapp.500"
        />
      )} */}
    </Flex>
  );
};
