import { Flex, Text, Box } from "@chakra-ui/react";
import { AiOutlineRight } from "react-icons/ai";

interface IListItem {
  icon: any;
  title: string;
  desc?: string;
  colorScheme: string;
  onPress?: () => void;
}

const ListItem = ({ icon, title, desc, colorScheme, onPress }: IListItem) => {
  return (
    <Flex
      p={3}
      borderRadius="8px"
      borderWidth="1px"
      borderColor="gray.200"
      borderLeftWidth="4px"
      borderLeftColor={`${colorScheme}.500`}
      align="center"
      mb={4}
      onClick={() => {
        if (onPress) {
          onPress();
        }
      }}
    >
      <Flex
        bg={`${colorScheme}.100`}
        height="32px"
        minHeight="32px"
        width="32px"
        minWidth="32px"
        mr={4}
        color={`${colorScheme}.500`}
        align="center"
        justify="center"
        borderRadius="4px"
      >
        <Box fontSize="18px" as={icon} />
      </Flex>
      <Box width="100%" mr={4}>
        <Text width="100%">{title}</Text>
        {/* {desc ? <Text fontSize="sm">{desc}</Text> : <> </>} */}
      </Box>

      <Box as={AiOutlineRight} />
    </Flex>
  );
};

export default ListItem;
