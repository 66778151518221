import React, { useEffect, useState, useContext } from "react";
import { ThemeProvider, CSSReset } from "@chakra-ui/react";
import theme from "./theme";
import Navigation from "./navigation";
import Header from "./components/header";

import "nouislider/distribute/nouislider.css";
import "./App.css";
import StateContext, { StateContextWrapper } from "./context";

function App() {
  const [scode, setScode] = useState("");

  useEffect(() => {
    const url = new URL(window.location.href);
    // let sapcode = url.searchParams.get("sapcode") ?? "22463664";
    let sapcode = url.searchParams.get("sapcode");

    if (sapcode) {
      setScode(sapcode);
      localStorage.setItem("v1Sapcode", sapcode);
    } else {
      if (localStorage.getItem("v1Sapcode")) {
        setScode(localStorage.getItem("v1Sapcode") || "");
      }
    }
  }, []);

  if (!scode || scode == "") {
    return <> </>;
  }

  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <StateContextWrapper scode={scode}>
        <AppComponent />
      </StateContextWrapper>
    </ThemeProvider>
  );
}

export default App;

const AppComponent = () => {
  const stateCtx = useContext(StateContext);

  if (!stateCtx?.app?.user) {
    return <> </>;
  }

  return (
    <>
      <Header />
      <Navigation />
    </>
  );
};
