import React, { useContext, useState, useEffect } from "react";
import { Flex, Image, Button, Text } from "@chakra-ui/react";
import Layout from "../../components/layout";
import StateContext from "../../context";
import ShowCompletedCount from "./components/show-completed-count";
import StorageService from "../../service/storage";
import { STORAGE } from "../../constants/";
import ProgressWithMilestones from "./components/progress-with-milestones";
import RaceCourseWithLeaderBoard from "./components/race-course";
import BarActivity from "../../components/home/bar-activity";
import Disclaimer from "../../components/disclaimer";
import ErrorBoundary from "../../components/ErrorBoundary";

const Home = () => {
  const stateCtx = useContext(StateContext);
  const [type, setType] = useState("fyp");

  const onSelectChange = (val: string) => {
    if (val === "") {
      return;
    }

    setType(val);
    StorageService.set(STORAGE.UNIT, val);
  };

  // Target, Achieved, Position
  const [startVal, setStartVal] = useState(0);
  const [target, setTarget] = useState(0);
  const [pos, setPos] = useState(0);
  const [achieved, setAchived] = useState(0);

  const calculatePos = () => {
    if (
      !stateCtx?.app?.user ||
      !stateCtx?.app?.user[stateCtx?.campaign?.toUpperCase()]
    ) {
      return "-";
    }

    const typeObject =
      stateCtx?.app?.user[stateCtx?.campaign?.toUpperCase()] ?? null;

    const propName = `${type.toUpperCase()}POS`;

    if (typeObject && propName && typeObject[propName]) {
      return typeObject[propName];
    }

    return "-";
  };

  const reCalulateTargetPosAchieved = () => {
    const user = stateCtx?.app?.user;

    if (!user) return;

    // ACHEIEVED
    let reAchived = user[stateCtx?.campaign?.toUpperCase()][type.toUpperCase()];
    if (typeof reAchived === "number") {
      setAchived(reAchived);
    }

    // TARGET
    const typeArray = stateCtx?.app?.data?.[stateCtx?.campaign] ?? [];
    const typeObj = typeArray.find((el: any, index: number) => {
      if (typeof el[type] === "number" && el[type] > reAchived) {
        setTarget(el[type]);

        if (index > 0) {
          setStartVal(typeArray[index - 1][type]);
        } else {
          setStartVal(0);
        }

        return true;
      }

      return false;
    });

    if (!typeObj) {
      setStartVal(reAchived);
      setTarget(typeArray[typeArray.length - 1][type]);
    }

    // SET POSITION
    setPos(calculatePos());
  };

  useEffect(() => {
    reCalulateTargetPosAchieved();
  }, [type, stateCtx?.campaign]);

  if (!stateCtx?.app?.user) return <> </>;

  return (
    <>
      <Layout p={4}>
        <ShowCompletedCount type={type} onSelectChange={onSelectChange} />
        <ProgressWithMilestones
          target={target}
          position={pos}
          achieved={achieved}
          startVal={startVal}
        />
        <RaceCourseWithLeaderBoard
          target={target}
          type={type}
          achieved={achieved}
        />

        <ErrorBoundary>
          <BarActivity />
        </ErrorBoundary>

        <Disclaimer />
        <br />
        <br />
      </Layout>
    </>
  );
};

export default Home;
