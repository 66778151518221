import { APPNAME, APPVERSION } from "../constants";

export const set = (key: string, value: string) => {
  return localStorage.setItem(`${APPNAME}-${APPVERSION}-${key}`, value);
};

export const get = (key: string) => {
  return localStorage.getItem(`${APPNAME}-${APPVERSION}-${key}`);
};

export default {
  set,
  get,
};
