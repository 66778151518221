import Layout from "../components/layout";
import { Text, Box, Image, Flex, Divider } from "@chakra-ui/react";
import ListItem from "../components/referral/list-item";
import ProtectoMeter from "../components/referral/protect-o-meter";
import PartnerIncomeCalculator from "../components/referral/partner-income-calc";
import DigitalVisitingCard from "../components/referral/digital-visiting-card";
import AVCard from "../components/referral/AV";
import { MdSavings } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import Disclaimer from "../components/disclaimer";
import RetirementCalculator from "../components/referral/retirement-calc";

const ReferralScreen = () => {
  const history = useHistory();

  const routeTo = (val: string) => {
    history.push(val);
  };

  return (
    <Layout p={4}>
      <Box mx={-4} my={4} mt={-4}>
        <Image src="/images/collage.jpg" mb={2} />

        <Flex
          my={6}
          px={4}
          direction="column"
          wrap={"wrap"}
          color="primary.500"
        >
          <Box as={FaQuoteLeft} />
          <Text fontFamily="heading" px={6} textAlign="center" mb={0} mt={-2}>
            When something is important enough, you do it even if the odds are
            not in your favour.
          </Text>
          <Box as={FaQuoteRight} ml="auto" mt={-4} />
        </Flex>

        <Text
          color="secondary.500"
          fontFamily="heading"
          textAlign="right"
          mr={6}
        >
          - Ashish Vohra <br />
          <span style={{ fontSize: "12px" }}> (CEO RNLIC)</span>
        </Text>
      </Box>

      <Text fontSize="sm" mt={4} mb={2}>
        Experience the world with RNLIC, through the eyes of our Super
        Achievers.
      </Text>

      {/* <ListItem
        icon={MdSavings}
        colorScheme="red"
        title="Tips from past achievers"
      /> */}

      <ListItem
        icon={MdSavings}
        colorScheme="teal"
        title="Success stories"
        desc=""
        onPress={() => routeTo("/success-stories")}
      />

      <Box mb={4}>
        <Divider width="100%" color="gray.400" />
      </Box>

      <Text fontSize="sm" mb={2}>
        Tools for higher closures. Share them with your customers
      </Text>
      {/* 
      <DigitalVisitingCard /> */}
      <Text fontSize="sm" mb={2}>
        Helps your customer plan their retirement better
      </Text>

      <RetirementCalculator />

      <ProtectoMeter />

      <Box mb={0}>
        <Divider width="100%" color="gray.400" />
      </Box>

      <Text fontSize="sm" mt={4} mb={2}>
        Plan your income with every business you close.
      </Text>

      <PartnerIncomeCalculator />

      <Box p={2}>
        <Disclaimer />
      </Box>

      {/* <AVCard /> */}

      <br />
      <br />
      <br />
    </Layout>
  );
};

export default ReferralScreen;
