import React, { useState, useContext, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import StateContext from "../../context";
import { Box, Text, Flex } from "@chakra-ui/react";

const RunRateChart = () => {
  const stateCtx = useContext(StateContext);

  const [data, setData] = useState({
    series: [
      {
        data: [44, 55],
        label: "ACHIEVED",
      },
      {
        data: [53, 32],
        label: "TARGET",
      },
    ],
  });

  useEffect(() => {
    const prpType = stateCtx?.campaign == "mdrt" ? "WRP" : "WCP";
    const cmpg = stateCtx?.campaign?.toUpperCase();

    if (!stateCtx?.app?.user) return;

    const dividend = 100000;
    const login = stateCtx?.app?.user[cmpg][`${prpType}LOGIN`] / dividend;
    const issuance = stateCtx?.app?.user[cmpg][`${prpType}ISSUANCE`] / dividend;
    const target = stateCtx?.app?.user[cmpg][`${prpType}TARGET`] / dividend;
    setData({
      series: [
        {
          data: [login, issuance, login],
          label: "ACHIEVED",
        },
        {
          data: [target, target, target],
          label: "TARGET",
        },
      ],
    });
  }, [stateCtx?.campaign]);

  if (!data) return <> </>;

  return (
    <>
      <div id="chart">
        <ReactApexChart
          options={{
            xaxis: {
              categories: ["MDRT", "SELECT", "CLUB"],
            },
            plotOptions: {
              bar: {
                horizontal: true,
                dataLabels: {
                  position: "top",
                },
              },
            },
          }}
          series={data.series}
          type="bar"
          height={250}
        />

        <Flex align="center" justify="center" direction="row">
          <Flex direction="row" align="center" mr={6}>
            <Box
              height="16px"
              width="16px"
              mr={1}
              bg="rgba(0, 143, 251, 0.85)"
            ></Box>
            <Text>Premium</Text>
          </Flex>

          <Flex direction="row" align="center">
            <Box
              height="16px"
              width="16px"
              mr={1}
              bg="rgba(0, 227, 150, 0.85)"
            ></Box>
            <Text>Commission</Text>
          </Flex>
        </Flex>
      </div>
    </>
  );
};

export default RunRateChart;
