import React, { useContext } from "react";
import StateContext from "../../../context";
import { Box, Text, Flex, Image, Button } from "@chakra-ui/react";

const ShowCompletedCount = ({
  type,
  onSelectChange,
}: {
  type: string;
  onSelectChange: (v: string) => void;
}) => {
  const stateCtx = useContext(StateContext);

  const count = () => {
    if (stateCtx?.campaign == "mdrt") {
      return stateCtx?.app.qualified.mdrt ?? 0;
    } else if (stateCtx?.campaign == "ceo") {
      return stateCtx?.app.qualified.ceo ?? 0;
    }

    return 0;
  };

  return (
    <>
      <Flex mb={4} textAlign="center">
        {stateCtx?.campaign == "mdrt" ? (
          <Image mx="auto" src="/images/MDRT-LOGO.png" height="75px" />
        ) : (
          <>
            <Image mx="auto" src="/images/club.png" height="75px" />
            <Image mx="auto" src="/images/members.png" height="75px" />
          </>
        )}
      </Flex>

      <Box
        bg={stateCtx?.campaign == "mdrt" ? "red.500" : "primary.500"}
        borderWidth="1px"
        borderColor="gray.200"
        p={2}
        py={4}
        borderRadius="8px"
        mb={6}
        shadow="lg"
      >
        <Text
          fontSize="12px"
          fontFamily="heading"
          color="white"
          textAlign="center"
        >
          {count()} users have already qualified
          <b
            style={{
              color:
                stateCtx?.campaign === "mdrt"
                  ? "var(--chakra-colors-primary-500)"
                  : "var(--chakra-colors-secondary-500)",
            }}
          >
            &nbsp;{stateCtx?.campaign === "mdrt" ? "MDRT" : "CEO's CLUB"}
          </b>
        </Text>
      </Box>

      <Flex pb={8}>
        <Button
          width="100%"
          borderTopRightRadius="0"
          borderBottomRightRadius="0"
          fontWeight="normal"
          colorScheme={type == "fyp" ? "primary" : "gray"}
          onClick={() => onSelectChange("fyp")}
          _focus={{ boxShadow: "none", outline: "0" }}
          size="sm"
          variant={type == "fyp" ? "solid" : "outline"}
        >
          PREMIUM
        </Button>
        <Button
          width="100%"
          borderTopLeftRadius="0"
          borderBottomLeftRadius="0"
          fontWeight="normal"
          colorScheme={type == "fyc" ? "primary" : "gray"}
          onClick={() => onSelectChange("fyc")}
          _focus={{ boxShadow: "none", outline: "0" }}
          size="sm"
          variant={type == "fyc" ? "solid" : "outline"}
        >
          COMMISSION
        </Button>
      </Flex>
    </>
  );
};

export default ShowCompletedCount;
