import React from "react";
import { Text } from "@chakra-ui/react";

const Disclaimer = () => {
  return (
    <Text fontSize="xs" color="gray.500">
      <b>Reliance Nippon Life Insurance Company Limited.</b> <br />
      IRDAI Registration No: 121. CIN: U66010MH2001PLC167089. Strictly for
      internal communication only.
    </Text>
  );
};

export default Disclaimer;
