import React from "react";
import {
  Flex,
  Image,
  Box,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Text,
} from "@chakra-ui/react";
import logo from "../assets/logo.png";
import { RiWhatsappFill } from "react-icons/ri";
import { AiFillPhone, AiFillMail } from "react-icons/ai";

const Header = () => {
  const message =
    "Hi, I would like to discuss with you regarding, helping me achieve my success milestones. Thank you.";

  const number = "9930602661";

  return (
    <Box>
      <Flex
        align="center"
        justify="space-between"
        width="100vw"
        maxWidth="600px"
        mx="auto"
        p={2}
        bg="secondary.500"
      >
        <Image src={logo} alt="RNLIC Logo" height="50px" />

        <Box position="relative">
          <Popover>
            <PopoverTrigger>
              <div>
                <Box fontSize="32px" as={AiFillPhone} color="primary.500" />
              </div>
            </PopoverTrigger>
            <PopoverContent mr={2}>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Box>
                  <Link
                    isExternal
                    href={`https://wa.me/91${number}?text=${encodeURI(
                      message
                    )}`}
                    _focus={{
                      boxShadow: "none",
                      outline: "0",
                    }}
                  >
                    <Flex align="center">
                      <Box
                        fontSize="32px"
                        as={RiWhatsappFill}
                        color="green.600"
                        mr={2}
                      />
                      <Text> Whatsapp : 9930602661</Text>
                    </Flex>
                  </Link>
                </Box>

                <Box>
                  <Link
                    isExternal
                    href={`mailto:abhijit.kudtarkar@relianceada.com`}
                    _focus={{
                      boxShadow: "none",
                      outline: "0",
                    }}
                  >
                    <Flex align="center">
                      <Box
                        fontSize="32px"
                        as={AiFillMail}
                        color="primary.500"
                        mr={2}
                      />
                      <Text> Abhijit.kudtarkar@relianceada.com</Text>
                    </Flex>
                  </Link>
                </Box>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      </Flex>
    </Box>
  );
};

export default Header;
