import Layout from "../components/layout";
import QRgauge from "../components/qr/gauage";

const QRScreen = () => {
  return (
    <Layout p={4} align="center" justify="center">
      <QRgauge />
    </Layout>
  );
};

export default QRScreen;
