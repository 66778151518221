import React, { useState, useEffect } from "react";
import { Flex, Box, Text } from "@chakra-ui/react";
import Nouislider from "nouislider-react";
import FormatNumber from "../../../helpers/format-number";
import ErrorBoundary from "../../../components/ErrorBoundary";

interface IProgressWithMilestones {
  target: number;
  position: number;
  achieved: number;
  startVal: number;
}

const ProgressWithMilestones = (props: IProgressWithMilestones) => {
  const showSlider = () => {
    if (props?.target === 0) return false;

    if (props?.target <= props?.achieved) return false;

    return true;
  };

  return (
    <>
      <Flex direction="row" justify="space-between" mb={3}>
        <Box>
          <Text mb={1} fontFamily="heading" fontSize="xs">
            Target
          </Text>
          <Text fontFamily="heading" color="primary.500">
            &#8377; {FormatNumber(props.target)}
          </Text>
        </Box>

        <Box textAlign="right">
          <Text mb={1} fontFamily="heading" fontSize="xs">
            Position
          </Text>
          <Text fontFamily="heading" color="green.500">
            {props.position}
          </Text>
        </Box>
      </Flex>
      <Flex direction="row" justify="space-between" mb={6}>
        <Box>
          <Text mb={1} fontFamily="heading" fontSize="xs">
            You Have Achieved
          </Text>
          <Text fontFamily="heading" color="red.500">
            &#8377; {FormatNumber(props.achieved)}
          </Text>
        </Box>

        <Box textAlign="right">
          <Text mb={1} fontFamily="heading" fontSize="xs">
            GAP
          </Text>
          <Text fontFamily="heading" color="secondary.500">
            &#8377;{" "}
            {props?.achieved <= props?.target
              ? FormatNumber(props.target - props.achieved)
              : 0}
          </Text>
        </Box>
      </Flex>

      <ErrorBoundary>
        {showSlider() ? (
          <>
            <Box py={8} px={4}>
              <CustomSliderComponent
                value={props?.achieved}
                target={props?.target}
                startVal={props?.startVal}
              />
            </Box>
            <Text
              mr={4}
              fontSize="10px"
              textAlign="right"
              mt={5}
              color="gray.400"
            >
              * Above numbers are in Lakhs
            </Text>
          </>
        ) : (
          <> </>
        )}
      </ErrorBoundary>
    </>
  );
};

export default ProgressWithMilestones;

const dividend = 100000;

interface ICustomSliderComponent {
  target: number;
  value: number;
  startVal?: number;
}

const CustomSliderComponent = ({
  target,
  value,
  startVal,
}: ICustomSliderComponent) => {
  const [sliderRefState, setSliderRefState] = useState<any>(null);

  // CHANGING SLIDERREFSTATE
  useEffect(() => {
    if (sliderRefState && sliderRefState?.noUiSlider) {
      sliderRefState?.noUiSlider?.reset();
    }
  }, [sliderRefState]);

  // WHEN TARGET OR VALUE CHANGE
  useEffect(() => {
    if (sliderRefState && sliderRefState?.noUiSlider) {
      setTimeout(() => {
        sliderRefState?.noUiSlider?.reset();
        sliderRefState?.noUiSlider?.set(value / dividend);
      }, 150);
    }
  }, [target, value, startVal]);

  useEffect(() => {
    if (sliderRefState && sliderRefState?.noUiSlider) {
      setTimeout(() => {
        sliderRefState?.noUiSlider?.reset();
        sliderRefState?.noUiSlider?.set(value / dividend);
      }, 150);
    }
  }, [value]);

  const max = () => {
    return target / dividend;
  };

  const min = () => {
    return startVal ? startVal / dividend : 0;
  };

  return (
    <Nouislider
      connect
      start={value / dividend}
      behaviour="tap"
      range={{
        min: min(),
        max: max(),
      }}
      pips={{ mode: "count", values: 5 }}
      tooltips={true}
      disabled={true}
      animate={true}
      instanceRef={(instance) => {
        if (!sliderRefState && instance) {
          setSliderRefState(instance);
        }
      }}
    />
  );
};
