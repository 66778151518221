import React, { useEffect, useContext } from "react";
import {
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
  useHistory,
} from "react-router-dom";

import RoadMapScreen from "./road-map";
import QRScreen from "./qr";
import ReferralScreen from "./refferal";
import { Box, Flex, Text } from "@chakra-ui/react";
import { AiFillHome } from "react-icons/ai";
import { MdTimeline } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { FaExchangeAlt } from "react-icons/fa";
import StateContext from "../context";
import Home2 from "./home";

const TabsScreen = () => {
  let { path, url } = useRouteMatch();

  return (
    <div>
      <TabsComponent />

      <Switch>
        <Route exact path={path}>
          <RedirectToHome />
        </Route>

        <Route path={`${path}/home`}>
          <Home2 />
        </Route>
        <Route path={`${path}/road-map`}>
          {/* <Text p={2}>500 Internal Server Error</Text> */}
          <RoadMapScreen />
        </Route>

        <Route path={`${path}/qr`}>
          <QRScreen />
        </Route>

        <Route path={`${path}/referral`}>
          <ReferralScreen />
        </Route>
      </Switch>
    </div>
  );
};

export default TabsScreen;

const RedirectToHome = () => {
  const history = useHistory();

  useEffect(() => {
    history.push("/tabs/home");
  }, []);

  return <div> </div>;
};

const TabsComponent = () => {
  let { path, url } = useRouteMatch();
  const stateCtx = useContext(StateContext);
  const history = useHistory();

  const checkIsActive = (route?: string) => {
    if (!route) return false;

    return window.location.href.includes(route);
  };

  const changeState = () => {
    if (stateCtx?.campaign == "ceo") {
      stateCtx?.setCampaign("mdrt");
    } else {
      stateCtx?.setCampaign("ceo");
    }

    if (!checkIsActive("home")) {
      history.push("/tabs/home");
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const tabsArray = [
    {
      id: "1",
      title: stateCtx?.campaign ? stateCtx?.campaign.toUpperCase() : "Home",
      path: "home",
      icon: AiFillHome,
    },
    {
      id: "4",
      title:
        stateCtx?.campaign && stateCtx?.campaign == "mdrt" ? "CEO" : "MDRT",
      path: null,
      icon: FaExchangeAlt,
      onclick: changeState,
    },
    {
      id: "4",
      title: "Tools",
      path: "referral",
      icon: FaUsers,
    },
    {
      id: "2",
      title: "Roadmap",
      path: "road-map",
      icon: MdTimeline,
    },
  ];

  const goToNextTab = (url?: string, cb?: () => void) => {
    if (url) {
      history.push(url);
    }

    if (cb) {
      cb();
    }
  };

  return (
    <Flex
      direction="row"
      align="center"
      justify="space-between"
      position="fixed"
      bottom="0"
      width="100vw"
      maxWidth="600px"
      p={4}
      py={3}
      bg="white"
      zIndex={5050}
      left="50%"
      transform="translateX(-50%)"
    >
      {tabsArray.map((t) => (
        <Box
          key={`${url}/${t.path}`}
          onClick={() => {
            if (t.path) {
              goToNextTab(`${url}/${t.path}`);
            } else {
              goToNextTab(undefined, changeState);
            }
          }}
          textAlign="center"
          color={
            checkIsActive(t?.path ? t.path : undefined)
              ? "secondary.500"
              : "#B8BCBF"
          }
        >
          <Box fontSize="20px" as={t.icon} mx="auto" />
          <Text fontFamily="heading" fontSize="xs" textAlign="center">
            {t.title}
          </Text>
        </Box>
      ))}
    </Flex>
  );
};
