import React, { useState, useContext, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import StateContext from "../../context";
import { Text } from "@chakra-ui/react";

const BarActivity = () => {
  const stateCtx = useContext(StateContext);

  const [data, setData] = useState({
    series: [
      {
        data: [44, 55],
        label: "ACHIEVED",
      },
      {
        data: [53, 32],
        label: "TARGET",
      },
    ],
  });

  useEffect(() => {
    const prpType = stateCtx?.campaign == "mdrt" ? "WRP" : "WCP";
    const cmpg = stateCtx?.campaign?.toUpperCase();

    if (!stateCtx?.app?.user) return;

    const dividend = 100000;
    const login = stateCtx?.app?.user[cmpg][`${prpType}LOGIN`] / dividend;
    const issuance = stateCtx?.app?.user[cmpg][`${prpType}ISSUANCE`] / dividend;
    const target = stateCtx?.app?.user[cmpg][`${prpType}TARGET`] / dividend;
    setData({
      series: [
        {
          data: [login, issuance],
          label: "ACHIEVED",
        },
        {
          data: [target, target],
          label: "TARGET",
        },
      ],
    });
  }, [stateCtx?.campaign]);

  if (!data) return <> </>;

  return (
    <>
      <div className="loginIssuance" id="chart">
        <ReactApexChart
          options={{
            xaxis: {
              categories: ["LOGIN", "ISSUANCE"],
            },
            plotOptions: {
              bar: {
                horizontal: true,
                dataLabels: {
                  position: "top",
                },
              },
            },
          }}
          series={data.series}
          type="bar"
          height={250}
        />
      </div>

      <Text color="gray.400" fontSize="sm" mb={4}>
        *Above figures are in lakhs
      </Text>
    </>
  );
};

export default BarActivity;
