import React, { useState, useEffect, useRef, useContext } from "react";
import Layout from "../components/layout";
import { Image, Box, Text, Flex, Button } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import Disclaimer from "../components/disclaimer";
import StateContext from "../context";

const StartScreen2 = () => {
  const history = useHistory();
  const stateCtx = useContext(StateContext);

  const goNext = () => {
    history.push("/select");
  };

  console.log(stateCtx);

  return (
    <Layout align="center" justify="space-between">
      <VideoScreen />
      <Box my={4} p={4} mb={0} textAlign="center">
        <Text
          mt={4}
          color="primary.700"
          fontSize="2xl"
          fontWeight="bold"
          mb={2}
        >
          Hi {stateCtx?.app?.user?.Name ? stateCtx?.app?.user?.Name : ""},
        </Text>
        <Text fontSize="lg" fontWeight="semibold" color="primary.500">
          Welcome to
          <span
            style={{
              color: "var(--chakra-colors-secondary-500)",
              fontWeight: "bold",
            }}
          >
            &nbsp; My Success MileStones Calculator
          </span>
          , We are determined to help you achieve your dream to be the No. 1.
          Come let’s make it a reality.
        </Text>
      </Box>

      <SelectLanguage />

      <Box mx={6} mb={8} mt={4}>
        <Button mt={4} colorScheme="secondary" width="100%" onClick={goNext}>
          Next
        </Button>
        <Text fontSize="xs" mt={2} color="blackalpha.400" textAlign="center">
          By Using this App you agree to the Terms & Conditions and Privacy
          policy.
        </Text>
      </Box>

      <Box p={2}>
        <Disclaimer />
      </Box>
    </Layout>
  );
};

//
const VideoScreen = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    loop: true,
    sources: [
      {
        src: "/video/intro-video.m4v",
        type: "video/mp4",
      },
    ],
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    console.log(videoRef, videoElement);

    if (!videoElement) return;

    const player = videojs(videoElement, videoJsOptions, () => {
      console.log("player", player);
    });

    return () => {
      if (playerRef.current) {
        //@ts-ignore
        playerRef.current?.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <Box width="100%">
      <div data-vjs-player>
        <video ref={videoRef} className="video-js vjs-big-play-centered" />
      </div>
    </Box>
  );
};

export default StartScreen2;

const SelectLanguage = () => {
  const [lang, setLang] = useState("english");

  return (
    <Box mb={4} width="100%" p={4}>
      <Text textAlign="center" fontSize="sm" color="gray.400" mb={1}>
        Select Language
      </Text>

      <Flex direction="row">
        <Button
          width="100%"
          borderTopRightRadius="0"
          borderBottomRightRadius="0"
          fontWeight="normal"
          colorScheme={lang == "hindi" ? "primary" : "gray"}
          onClick={() => setLang("hindi")}
          _focus={{ boxShadow: "none", outline: "0" }}
          size="sm"
        >
          हिन्दी
        </Button>
        <Button
          width="100%"
          borderTopLeftRadius="0"
          borderBottomLeftRadius="0"
          fontWeight="normal"
          colorScheme={lang == "english" ? "primary" : "gray"}
          onClick={() => setLang("english")}
          _focus={{ boxShadow: "none", outline: "0" }}
          size="sm"
        >
          English
        </Button>
        {/* <Button
          width="100%"
          fontWeight="normal"
          borderTopLeftRadius="0"
          borderBottomLeftRadius="0"
          colorScheme={lang == "marathi" ? "primary" : "gray"}
          onClick={() => setLang("marathi")}
          _focus={{ boxShadow: "none", outline: "0" }}
          size="sm"
        >
          मराठी
        </Button> */}
      </Flex>
    </Box>
  );
};
