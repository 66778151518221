import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Box,
  Flex,
  Text,
  Image,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
  Badge,
} from "@chakra-ui/react";
import Layout from "../components/layout";
import MDRT from "../assets/MDRT Banner.jpg";
import CEOCOUNCIL from "../assets/CEO Council Banner.jpg";
import storage from "../service/storage";
import { STORAGE } from "../constants";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import ReactCardFlip from "react-card-flip";
import StateContext from "../context";
import Disclaimer from "../components/disclaimer";

const SelectScreen = () => {
  const history = useHistory();
  const [flippedCard, setFilppedCard] = useState("");

  const goToNextStep = (value: string) => {
    storage.set(STORAGE.UNIT, value);
    history.push("/tabs/home");
  };

  return (
    <Layout p={4}>
      <Text fontSize="lg" mb={4} textAlign="center" color="blackalpha.400">
        Please click on anyone of the below banners for more details
      </Text>

      <Box textAlign="center">
        <FlipImageBox
          img={"/images/CEO Council Banner.jpg"}
          activeType={flippedCard}
          type={"mdrt"}
          onClickImage={(val) => setFilppedCard(val)}
          url="/tabs/home"
          dateRange="1st Jan 21 to 31st Dec 21"
          fyp="29,38,800"
          fypRunRate="56,515"
          fyc="7,34,700"
          fycRunRate="14,128"
        />

        <FlipImageBox
          img={"/images/MDRT Banner.jpg"}
          activeType={flippedCard}
          type={"ceo's Club"}
          onClickImage={(val) => setFilppedCard(val)}
          url="/tabs/home"
          dateRange="1st July 21 to 30th June 22"
          fyp="88,16,400"
          fypRunRate="169,546"
          fyc="22,04,100"
          fycRunRate="44,028"
        />
      </Box>

      <Text fontSize="lg" textAlign="center" color="blackalpha.400">
        Please click on anyone of the above banners for more details
      </Text>

      <Box p={2} mt={4}>
        <Disclaimer />
      </Box>
    </Layout>
  );
};

export default SelectScreen;

interface IFlipImageBox {
  img: string | any;
  activeType: string;
  type: string;
  onClickImage: (type: string) => void;
  url: string;
  dateRange?: string;
  fyp?: string;
  fypRunRate?: string;
  fyc?: string;
  fycRunRate?: string;
}

const FlipImageBox = ({
  img,
  activeType,
  type,
  onClickImage,
  url,
  dateRange,
  fyp,
  fypRunRate,
  fyc,
  fycRunRate,
}: IFlipImageBox) => {
  const boxWrapperRef = useRef<HTMLDivElement>(null);
  const [boxWidth, setBoxWidth] = useState("328px");
  const imageRef = useRef<HTMLImageElement>(null);
  const [boxHeight, setBoxHeight] = useState("");
  const stateCtx = useContext(StateContext);

  const history = useHistory();

  const goToNextStep = () => {
    if (type == "mdrt") {
      stateCtx?.setCampaign("mdrt");
    } else {
      stateCtx?.setCampaign("ceo");
    }

    storage.set(STORAGE.UNIT, type);
    history.push("/tabs/home");
  };

  useEffect(() => {
    let w = boxWrapperRef.current?.clientWidth;

    if (w) {
      if (w > 400) {
        w = 400;
      }

      setBoxWidth(`${w}px`);
      let h = w / 1.2;

      if (h < 325) {
        h = 325;
      }

      setBoxHeight(`${h}px`);
    }
  }, [boxWrapperRef]);

  return (
    <Box
      mb={4}
      width="100%"
      borderRadius="8px"
      overflow="hidden"
      ref={boxWrapperRef}
      mx="auto"
    >
      <Badge
        mx="auto"
        px={4}
        pt={1}
        color="white"
        bg={type == "mdrt" ? "red.500" : "primary.500"}
      >
        {type}
      </Badge>
      <ReactCardFlip isFlipped={activeType == type ? true : false}>
        <Box
          width={boxWidth}
          height={boxHeight}
          position="relative"
          backgroundSize="cover"
          backgroundPosition="center center"
          backgroundImage={`url("${img}")`}
          boxShadow="0 3px 10px rgb(0 0 0 / 0.2)"
          onClick={() => onClickImage(type)}
          mx="auto"
        ></Box>
        <Flex
          width={boxWidth}
          height={boxHeight}
          bg="gray.100"
          borderRadius="8px"
          p={4}
          direction="column"
          justify="space-between"
          mx="auto"
        >
          <Text
            color="secondary.500"
            fontSize="2xl"
            fontFamily="heading"
            letterSpacing="2px"
            textAlign="center"
            textTransform="uppercase"
            mb={0}
          >
            {type}
          </Text>
          <small> {dateRange}</small>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th px={1}></Th>
                <Th px={0} textAlign="right">
                  Premium (&#8377;)
                </Th>
                <Th px={0} textAlign="right">
                  Comission (&#8377;)
                </Th>
              </Tr>
            </Thead>

            {type == "mdrt" ? (
              <Tbody>
                <Tr>
                  <Th px={1}>MDRT</Th>
                  <Td px={1} textAlign="right">
                    29,38,800
                  </Td>
                  <Td px={1} textAlign="right">
                    7,34,700
                  </Td>
                </Tr>
                <Tr>
                  <Th px={1}>COT</Th>
                  <Td px={1} textAlign="right">
                    88,16,400
                  </Td>
                  <Td px={1} textAlign="right">
                    22,04,100
                  </Td>
                </Tr>
                <Tr>
                  <Th px={1}>TOT</Th>
                  <Td px={1} textAlign="right">
                    1,76,32,800
                  </Td>
                  <Td px={1} textAlign="right">
                    44,08,200
                  </Td>
                </Tr>
              </Tbody>
            ) : (
              <Tbody>
                <Tr>
                  <Th px={1}>SELECT</Th>
                  <Td px={1} textAlign="right">
                    29,00,000
                  </Td>
                  <Td px={1} textAlign="right">
                    7,40,000
                  </Td>
                </Tr>
                <Tr>
                  <Th px={1}>CABINET</Th>
                  <Td px={1} textAlign="right">
                    40,00,000
                  </Td>
                  <Td px={1} textAlign="right">
                    11,00,000
                  </Td>
                </Tr>
              </Tbody>
            )}
          </Table>

          <Button
            fontWeight="300"
            fontFamily="heading"
            colorScheme="primary"
            fontSize="xs"
            py={2}
            onClick={goToNextStep}
          >
            Click Here For More Details
          </Button>
        </Flex>
      </ReactCardFlip>
    </Box>
  );

  return (
    <Box mb={4} borderRadius="8px" overflow="hidden" ref={boxWrapperRef}>
      <ReactCardFlip isFlipped={activeType == type ? true : false}>
        <Image
          src={img}
          width="100%"
          boxShadow="0 3px 10px rgb(0 0 0 / 0.2)"
          onClick={() => onClickImage(type)}
          ref={imageRef}
        />
        <Flex
          height={`${boxHeight}px`}
          width="100%"
          bg="gray.100"
          borderRadius="8px"
          p={4}
          direction="column"
          justify="space-between"
        >
          <Text
            color="secondary.500"
            fontSize="2xl"
            fontFamily="heading"
            letterSpacing="2px"
            textAlign="center"
            textTransform="uppercase"
            mb={0}
          >
            {type}
          </Text>

          <Table fontSize="sm" textAlign="center" my={2}>
            <Tbody>
              <Tr>
                <Td p={2}>Date: </Td>
                <Td p={2}> {dateRange} </Td>
              </Tr>
              <Tr>
                <Td p={2}>FYP Criteria: </Td>
                <Td p={2}>
                  <Box>
                    &#8377; {fyp} /-
                    <Text fontSize="xs" color="blackalpha.400">
                      &#8377; {fypRunRate} /- Req. run rate per week
                    </Text>
                  </Box>
                </Td>
              </Tr>
              <Tr>
                <Td p={2}>FYC Criteria </Td>
                <Td p={2}>
                  <Box>
                    &#8377; {fyc} /-
                    <Text fontSize="xs" color="blackalpha.400">
                      &#8377; {fycRunRate} /- Req. run rate per week
                    </Text>
                  </Box>
                </Td>
              </Tr>
            </Tbody>
          </Table>

          <Button
            fontWeight="300"
            fontFamily="heading"
            colorScheme="primary"
            fontSize="xs"
            py={2}
            onClick={goToNextStep}
          >
            Click Here For More Details
          </Button>
        </Flex>
      </ReactCardFlip>
    </Box>
  );
};
