import React, { useState, useEffect, useContext } from "react";
import { Box, Flex, Text, Divider } from "@chakra-ui/react";
import StateContext, { BASE } from "../../../context";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import AnimatedCar from "../../../components/home/animated-car";
import getWeeks from "../../../helpers/date";
import { recalculatePos } from "../../../helpers/calculate";
import _ from "lodash";
import LeaderBoard from "../../../components/home/leader-board";
import Disclaimer from "../../../components/disclaimer";

const PER_QR = {
  fyp: BASE.FYP / 8,
  fyc: BASE.FYC / 8,
};

const RaceCourseWithLeaderBoard = ({
  type,
  target,
  achieved,
}: {
  type: string;
  target: number;
  achieved: number;
}) => {
  const stateCtx = useContext(StateContext);
  const [minQrs, setMinQrs] = useState(0);
  const [tarQrs, setTarQrs] = useState(0);
  const [tarPos, setTarPos] = useState(0);
  const [tarWeeks, setTarWeeks] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);

  const handleRemoveQrs = () => {
    if (tarQrs - 1 < minQrs) {
      return;
    }

    setTarQrs(tarQrs - 1);
  };

  const handleAddQrs = () => {
    setTarQrs(tarQrs + 1);
  };

  const recalculateParams = () => {
    const weeks = getWeeks(stateCtx?.campaign?.toUpperCase());
    if (target <= 0 || target - achieved <= 0 || weeks <= 0) {
      setMinQrs(0);
      setTarQrs(0);
      setTarWeeks(0);
      return;
    }
    //Calculate Target QR
    //@ts-ignore
    const tQr = Math.round((target - achieved) / PER_QR[`${type}`] / weeks);

    if (tQr > 0) {
      setMinQrs(tQr);
      setTarQrs(tQr);
      setTarWeeks(weeks);
    } else {
      setMinQrs(0);
      setTarQrs(0);
    }
  };

  useEffect(() => {
    recalculateParams();
  }, [stateCtx?.campaign, type, target, achieved]);

  const recalculatePosition = () => {
    const uArray = stateCtx?.app?.getUsersArray();

    const result = recalculatePos(
      stateCtx?.app?.user,
      uArray,
      minQrs,
      tarQrs,
      stateCtx?.campaign?.toUpperCase(),
      type.toUpperCase()
    );

    if (result.pos) {
      setTarPos(result.pos);

      if (result.pos <= 10) {
        setUsers(result?.users.slice(0, 10));
      } else {
        setUsers([
          ...result?.users.slice(0, 10),
          result?.users[result.pos - 1],
        ]);
      }
    }
  };

  useEffect(() => {
    if (tarQrs <= 0) {
      setTarWeeks(0);
      return;
    }
    const maxWeeks = getWeeks(stateCtx?.campaign?.toUpperCase());

    const minWeeks = Math.round(
      //@ts-ignore
      (target - achieved) / (PER_QR[`${type}`] * tarQrs)
    );

    setTarWeeks(minWeeks);

    recalculatePosition();
  }, [tarQrs]);

  return (
    <>
      <Box my={4}>
        <Box mx={-4} position="relative" mb={8}>
          <AnimatedCar
            type={type.toUpperCase()}
            campaign={stateCtx?.campaign?.toUpperCase()}
            user={stateCtx?.app?.user}
            targetPos={tarPos}
          />
        </Box>

        <Flex direction="row" justify="space-between" mb={4}>
          <Text fontFamily="heading" fontSize="xs" color="gray.400">
            To achieve your next milestone you will need minimum of
          </Text>
          <Box ml="auto">
            <Text
              ml="auto"
              minWidth="95px"
              fontFamily="heading"
              fontSize="sm"
              textAlign="right"
              letterSpacing="1.2px"
            >
              {minQrs <= 0 ? 0 : minQrs} <br />{" "}
              <span style={{ fontSize: "12px", opacity: 0.6 }}>QRs/Week</span>
            </Text>
          </Box>
        </Flex>

        <Flex direction="row" justify="space-between" mb={4}>
          <Text fontFamily="heading" fontSize="xs" color="gray.400">
            MY QR target per Week
          </Text>
          <Box>
            <Flex direction="row" align="center">
              <Box
                as={AiFillMinusCircle}
                fontSize="2xl"
                color="red.500"
                onClick={handleRemoveQrs}
                opacity={tarQrs <= minQrs ? "0.5" : "1"}
              />
              <Text fontFamily="heading" mx={4}>
                {tarQrs <= 0 ? 15 : tarQrs}
              </Text>
              <Box
                as={AiFillPlusCircle}
                fontSize="2xl"
                color="green.500"
                onClick={handleAddQrs}
              />
            </Flex>
          </Box>
        </Flex>

        <Flex direction="row" justify="space-between" mb={4}>
          <Text fontFamily="heading" fontSize="xs" color="gray.400">
            You will become {stateCtx?.campaign?.toUpperCase()} in
          </Text>
          <Box>
            <Flex direction="row" align="center">
              <Text fontFamily="heading" mx={4} fontSize="sm" textAlign="right">
                {tarWeeks} Weeks
              </Text>
            </Flex>
          </Box>
        </Flex>

        <Flex direction="row" justify="space-between" mb={4}>
          <Text fontFamily="heading" fontSize="xs" color="gray.400">
            Projected Position
          </Text>
          <Box>
            <Flex direction="row" align="center">
              <Text fontFamily="heading" mx={4} fontSize="sm" textAlign="right">
                {tarPos}
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Box>

      <Divider color="gray.400" />

      <LeaderBoard type={type} users={users} />
    </>
  );
};

export default RaceCourseWithLeaderBoard;

const RaceCourse = () => {
  return <> </>;
};
