import { BASE, SortAndAddPos } from "../context";
import getWeeks from "./date";
import { map, find } from "lodash";

export const weeksRequired = (
  gap: number,
  campaign: string,
  type: string,
  tarQr: number
) => {
  let nops = tarQr / 8;

  if (type == "FYC") {
    nops = nops * BASE.FYC;
  } else {
    nops = nops * BASE.FYP;
  }

  return Math.ceil(gap / nops) || 0;
};

export const calculateGap = (
  u: any,
  total: number,
  typ: string,
  prty: string
) => {
  return total - u[typ][prty] ?? "0";
};

export const qrsRequiredPerWeek = () => {
  let minQrsPerWeek = 0;

  return minQrsPerWeek;
};

export const recalculatePos = (
  user: any,
  usersArray: Array<any>,
  minQr: number,
  targetQr: number,
  campaign: string,
  typ: string
) => {
  let data = {
    pos: 0,
    users: [...usersArray],
  };

  if (minQr === targetQr) {
    data["users"] = map(data["users"], (u) => {
      if (u.SAP === user.SAP) {
        return { ...user };
      }

      return u;
    });

    data["users"] = SortAndAddPos(
      usersArray,
      campaign,
      `${campaign}.${typ}`,
      `${typ}POS`
    );
    data["pos"] = user[campaign][`${typ}POS`];

    return data;
  } else {
    let amtPerQr = 0;

    if (typ == "FYC") {
      amtPerQr = BASE.FYC;
    } else if (typ == "FYP") {
      amtPerQr = BASE.FYP;
    }

    if (amtPerQr) {
      amtPerQr = amtPerQr / 8;
    }

    const weeks = getWeeks(campaign);

    const inOneWeek = targetQr * amtPerQr;

    const totalQRs = weeks * targetQr;

    const totalAmt = totalQRs * amtPerQr;

    let newUser = {
      ...user,
      [campaign]: {
        ...user[campaign],
        [typ]: user[campaign][typ] + inOneWeek,
      },
    };

    let newUserArray = map(usersArray, (u) => {
      if (u.SAP === user.SAP) {
        return { ...newUser };
      }

      return u;
    });

    newUserArray = SortAndAddPos(
      newUserArray,
      campaign,
      `${campaign}.${typ}`,
      `${typ}POS`
    );

    let sortedUser = find(newUserArray, (u) => u.SAP == user.SAP);

    data["pos"] = sortedUser[campaign][`${typ}POS`];
    data["users"] = [...newUserArray];
  }

  return data;
};
