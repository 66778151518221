import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
  useLocation,
} from "react-router-dom";

import StartScreen from "./screens/start";
import SelectScreen from "./screens/select";
import TabsScreen from "./screens/tabs";
import LoadingScreen from "./screens/loading";
import SuccessStories from "./screens/SuccessStories";

const Navigation = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          <LoadingScreen />
        </Route>
        <Route path="/start">
          <StartScreen />
        </Route>
        <Route path="/select">
          <SelectScreen />
        </Route>
        <Route path="/tabs">
          <TabsScreen />
        </Route>

        <Route path="/success-stories">
          <SuccessStories />
        </Route>
      </Switch>
    </Router>
  );
};

export default Navigation;

export function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location?.pathname]);

  return null;
}
