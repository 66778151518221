import React, { FC } from "react";
import { Box, Image, Text, Badge, Flex } from "@chakra-ui/react";

import BlueCar from "../../../assets/car/blue.png";
import RedCar from "../../../assets/car/red.png";
import YellowCar from "../../../assets/car/yellow.png";

interface ICar {
  type?: "blue" | "red" | "yellow";
  pos?: number;
}

const Car: FC<ICar> = ({ type, pos }) => {
  let leftPos = "50%";

  const carType = () => {
    switch (type) {
      case "blue":
        return BlueCar;

      case "red":
        return RedCar;

      default:
        leftPos = "45%";
        return YellowCar;
    }
  };

  return (
    <Flex position="relative">
      <Box display="inline-block" transform="rotate(-90deg)">
        <Badge> {pos} </Badge>
      </Box>
      <Image src={carType()} height="50px" />
    </Flex>
  );
};

export default Car;
