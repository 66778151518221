import React, { useState, useEffect, useContext } from "react";
import Chart from "react-apexcharts";
import theme from "../../theme";
import { Box, Text } from "@chakra-ui/react";
import StateContext, { BASE } from "../../context";
import getWeeks from "../../helpers/date";

const PERQR = {
  fyp: BASE.FYP / 8,
  fyc: BASE.FYC / 8,
};

const QRgauge = () => {
  const stateCtx = useContext(StateContext);
  const [gap, setGap] = useState(0);
  const [t, setT] = useState(0);
  const [totalQRs, setTotalQrs] = useState(0);
  const [completedQrs, setCompletedQrs] = useState(0);
  const weeks = getWeeks(stateCtx?.campaign?.toUpperCase());

  useEffect(() => {
    const user = stateCtx?.app?.user;
    let target = 0;

    // ACHEIEVED
    let reAchived = user[stateCtx?.campaign?.toUpperCase()].FYP;
    console.log(reAchived);

    // TARGET
    const typeArray = stateCtx?.app?.data?.[stateCtx?.campaign] ?? [];
    const typeObj = typeArray.find((el: any, index: number) => {
      if (typeof el.fyp === "number" && el.fyp > reAchived) {
        target = el.fyp;
        return true;
      }

      return false;
    });

    if (!typeObj) {
      target = typeArray[typeArray.length - 1].fyp;
    }

    setT(target);
    setGap(target - reAchived);
    const resultTQrs = Math.ceil(target / BASE.FYP);
    setTotalQrs(resultTQrs);
    setCompletedQrs(Math.ceil(reAchived / BASE.FYP));
  }, []);

  useEffect(() => {
    if (!gap || gap <= 0) return;
  }, [gap]);

  if (!gap) return <> </>;
  if (totalQRs <= completedQrs) return <> </>;

  return (
    <Box mb={8}>
      <Box my={4} textAlign="center" mb={0} px={4}>
        <Text fontWeight="bold" fontSize="lg">
          Keep It Up!
        </Text>

        <Text px={4}>
          You just need{" "}
          <span
            style={{
              color: "var(--chakra-colors-secondary-500)",
              fontWeight: "bold",
            }}
          >
            meet {Math.ceil((totalQRs - completedQrs) / (weeks * 5))} or more
            prospects every day
          </span>{" "}
          &nbsp; to achieve your next goal
        </Text>
      </Box>
      <Box width="80%" mx="auto">
        <Chart
          type="radialBar"
          sparkLine={true}
          options={{
            chart: {
              width: "200px",
              animations: {
                enabled: true,
                easing: "easeinout",
                speed: 800,
                animateGradually: {
                  enabled: true,
                  delay: 1500,
                },
                dynamicAnimation: {
                  enabled: true,
                  speed: 500,
                },
              },
            },
            plotOptions: {
              radialBar: {
                startAngle: -135,
                endAngle: 135,
                offsetY: 0,
                hollow: {
                  size: "60%",
                },
                track: {
                  strokeWidth: "90%",
                  background: "#fff",
                },
                dataLabels: {
                  value: {
                    offsetY: -10,
                    color: "#000",
                    fontSize: "32px",
                  },
                  name: {
                    fontSize: "12px",
                    color: theme.colors.gray["900"],
                    offsetY: 30,
                  },
                },
              },
            },
            fill: {
              type: "gradient",
              gradient: {
                shade: "dark",
                type: "horizontal",
                shadeIntensity: 0.5,
                gradientToColors: [theme.colors.primary["500"]],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, totalQRs],
              },
            },
            stroke: {
              dashArray: 3,
            },
            labels: [`${completedQrs} of ${totalQRs}`],
          }}
          series={[completedQrs]}
        />
      </Box>

      <Box
        zIndex={2000}
        mt="-20px"
        bg="white"
        width="150px"
        p={2}
        textAlign="center"
        mx="auto"
        borderRadius="24px"
        boxShadow="8px 12px 18px 0 rgb(25 42 70 / 13%);"
      >
        <Text color="secondary.500" fontWeight="bold" fontSize="lg">
          Prospects
        </Text>
      </Box>

      <Text textAlign="center" p={4}>
        Meeting {totalQRs - completedQrs} more prospects will increase your
        chances of achieveing your next goal
      </Text>
      <br />
      <br />
    </Box>
  );
};

export default QRgauge;
