import React, { useEffect, useRef, useState } from "react";
import { Box, Flex, Text, Select } from "@chakra-ui/react";
import Styles from "./game.module.scss";
import Car from "../game/car";
import { motion } from "framer-motion";

interface IAnimatedCar {
  targetPos: number;
  user: any;
  campaign: string;
  type: string;
}

const AnimatedCar = ({ targetPos, user, campaign, type }: IAnimatedCar) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [pos, setPos] = useState(0);
  const [isRaceStart, setIsRaceStart] = useState(false);
  const [direction, setDirection] = useState<"forward" | "backward" | null>(
    null
  );

  const [animateTrack, setAnimateTrack] = useState(false);

  const startAudio = new Audio("/sound/start.mp3");

  useEffect(() => {
    if (wrapperRef && wrapperRef.current) {
      let h = wrapperRef.current.offsetWidth / 2.2;

      if (h > 156) {
        h = 156;
      }

      setHeight(h);
    }
  }, [wrapperRef]);

  useEffect(() => {
    // START THE AUDIO
    startAudio.play();

    setTimeout(() => {
      setIsRaceStart(true);

      setTimeout(() => {
        setAnimateTrack(true);
      }, 1750);
    }, 4500);

    return () => {
      if (startAudio.played) {
        startAudio.pause();
      }

      startAudio.remove();
    };
  }, []);

  useEffect(() => {
    const actualPos = user[campaign][`${type}POS`];

    if (!direction) {
      if (actualPos < targetPos) {
        setDirection("backward");
      } else if (actualPos > targetPos) {
        setDirection("forward");
      } else {
        setDirection(null);
      }
    } else {
      if (direction === "forward") {
        if (actualPos < targetPos) {
          setDirection("backward");
        } else if (actualPos > targetPos) {
          setDirection(null);

          setTimeout(() => {
            setDirection("forward");
          }, 150);
        } else {
          setDirection("forward");
        }
      } else {
        if (actualPos < targetPos) {
          setDirection(null);

          setTimeout(() => {
            setDirection("backward");
          }, 150);
        } else if (actualPos > targetPos) {
          setDirection("forward");
        } else {
          setDirection("backward");
        }
      }
    }
  }, [targetPos]);

  const foward = {
    initial: { x: 1000, y: -135, position: "absolute" },
    animate: {
      x: -250,
      y: -130,
      zIndex: 1050,
    },
  };

  const backward = {
    initial: { x: -1000, y: -135, position: "absolute" },
    animate: {
      x: 1000,
      y: -130,
      zIndex: 1050,
    },
  };

  return (
    <>
      <Box>
        <Box
          className={`${Styles.container}`}
          ref={wrapperRef}
          height={height ? `${height}px` : "0px"}
        >
          <div
            className={`${Styles.slidingBackground} ${
              animateTrack ? Styles.animateRoad : ""
            }`}
            style={{
              backgroundImage: `url("/images/plain-road.png") `,
            }}
          ></div>

          {direction ? (
            <motion.div
              initial={{ x: 1000, y: -135, position: "absolute" }}
              animate={
                isRaceStart
                  ? {
                      x: -250,
                      y: -130,
                      zIndex: 1050,
                    }
                  : {}
              }
              transition={{
                type: "tween",
                delay: 0,
                duration: 5,
              }}
            >
              <Car type="yellow" />
            </motion.div>
          ) : (
            ""
          )}

          <motion.div
            initial={{ x: -250, y: -70, position: "absolute" }}
            animate={
              isRaceStart
                ? {
                    x: 80,
                    y: -70,
                    zIndex: 1050,
                  }
                : {}
            }
            transition={{
              type: "tween",
              delay: 0,
              duration: 3.5,
            }}
          >
            <Car type={campaign == "MDRT" ? "red" : "blue"} />
          </motion.div>
        </Box>
      </Box>
    </>
  );
};

export default AnimatedCar;
